import React from "react";

export type FeatCardProps = {
  image: string;
  title: JSX.Element;
  description: JSX.Element;
};

export const FeatureCard = ({ image, title, description }: FeatCardProps) => (
  <div className="flex w-[calc(100%-32px)] flex-col items-center space-y-2 text-center ">
    <div className="h-40 w-40 xl:h-52 xl:w-52">
      <img className="h-full w-full object-cover" src={image} alt="" />
    </div>
    <h3 className="text-2xl font-bold xl:text-4xl">{title}</h3>
    <p className="max-w-2xl text-btq-sub-text-normal xl:w-auto xl:text-lg">{description}</p>
  </div>
);
