import React from "react";
import { FormattedMessage } from "react-intl";

import useNavigateWithLocale from "hooks/useNavigateWithLocale";

import { Button, GhostButton } from "components/Button";
import { Section } from "components/Section";
import { Title } from "components/Title";
import Video from "./Video";
import BgImg from "./background";
import { SlideUp } from "components/Effects/SlideUp";

import { ANCHOR, ANCHOR_HREF } from "constants/anchor";
import COLOR from "constants/design";
import { SLUG } from "constants/slug";

const AboutUsDescription = ({ className = "" }: { className?: string }) => {
  const { navigateHandler } = useNavigateWithLocale();

  return (
    <div className={`flex max-w-2xl flex-col gap-y-5 xl:justify-between ${className}`}>
      <p className="text-center leading-4 text-white sm:text-xl xl:xl:text-left">
        <FormattedMessage id="HOME_ABOUT_INTRO" />
      </p>

      <div className="flex justify-center space-x-3  xl:justify-start">
        <Button className="sm:px-12" onClick={() => navigateHandler(SLUG.CAREER)}>
          <FormattedMessage id="HOME_ABOUT_JOIN_US" />
        </Button>
        <GhostButton
          className="sm:px-10 sm:py-5"
          onClick={() => navigateHandler(`${SLUG.TEAM}${ANCHOR_HREF[ANCHOR.MEET_OUR_TEAM]}`)}
        >
          <FormattedMessage id="HOME_ABOUT_MEET_OUR_TEAM" />
        </GhostButton>
      </div>
    </div>
  );
};

const AboutUs = () => (
  <Section background={{ color: COLOR.BACKGROUND_LIGHT, component: BgImg }}>
    <div className="flex flex-col justify-center pt-3 pb-12 sm:py-14 xl:justify-start xl:pb-52 xl:pt-36">
      <Title className="items-center xl:items-start">
        <FormattedMessage id="HOME_ABOUT_TITLE" />
      </Title>

      <SlideUp
        triggerOnce
        className="mt-6 flex flex-col items-center xl:flex-row xl:items-start xl:gap-x-8"
      >
        <AboutUsDescription className="mb-6 xl:mb-0 xl:flex-1 xl:self-stretch" />
        <Video className="xl:flex-1" />
      </SlideUp>
    </div>
  </Section>
);

export default AboutUs;
