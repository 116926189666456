import React, { FC, useContext, useMemo, useRef } from "react";
import { FormattedMessage } from "react-intl";
import { Section } from "components/Section";
import { Title } from "components/Title";
import { GhostButton } from "components/Button";
import COLOR from "constants/design";

import { graphql, useStaticQuery } from "gatsby";
import type { ArticleNode } from "interface/sanityNode";
import {
  handleArticleRelatedDataToCardProps,
  handleCmsArticleRelatedData,
} from "utils/cms/article";
import useNavigateWithLocale from "hooks/useNavigateWithLocale";
import { useOverflow } from "hooks/useOverflow";
import { SLUG } from "constants/slug";
import { i18nContext } from "context/i18n";
import { LANGUAGE_FORMATTED_LIST } from "constants/i18n";
import { SanityImage } from "components/SanityImage";
import { DefaultImg, Image } from "components/PostCard";

const blogArticles = graphql`
  query GetLatestThreeBlogArticles {
    allSanityBlog(sort: { fields: articlePublishedTime, order: DESC }, limit: 3) {
      nodes {
        _id
        _rawArticleContent
        _rawArticleSlug
        _rawArticleTitle
        _rawMainImage
        articleAuthor
        articlePublishedTime
      }
    }
  }
`;

interface CardProps {
  date: string;
  image: Image;
  title: string;
  paragraphClip: string;
  href: string;
  isFirstCard: boolean;
}

const LatestNewsCard: FC<CardProps> = ({
  date,
  image,
  title,
  paragraphClip,
  href,
  isFirstCard,
}) => {
  const { navigateHandler } = useNavigateWithLocale();
  const { mainImg, crop, hotspot } = image;
  // given [400px] due to feature of x scrolling
  return (
    <div
      className={`flex w-[min(calc(100vw-2rem),25rem)] flex-none snap-center flex-col text-white xl:shrink ${
        isFirstCard ? "bg-btq-card-bg-light" : "bg-btq-card-bg-normal"
      }`}
    >
      <div className="h-64 w-full">
        {mainImg === "" ? (
          <DefaultImg />
        ) : (
          <SanityImage
            width={400}
            _ref={mainImg}
            crop={crop}
            hotspot={hotspot}
            className="h-full w-full object-cover"
            alt={paragraphClip}
          />
        )}
      </div>
      <div className="flex w-full flex-col pt-4 pr-6 pb-6 pl-4 md:pb-14">
        <span className="mb-4 text-sm text-btq-sub-text-light">{date}</span>
        <h3 className="mb-4 h-16 text-2xl line-clamp-2">{title}</h3>
        <p className="mb-4 max-h-12 line-clamp-2">{paragraphClip}</p>
        <div className="self-start">
          <GhostButton
            className="sm:px-5 sm:py-2 sm:text-base"
            onClick={() => navigateHandler(href)}
          >
            <FormattedMessage id="HOME_NEWS_READ_MORE" />
          </GhostButton>
        </div>
      </div>
    </div>
  );
};

const LatestNewsScrollPadding = (props: React.ComponentProps<"div">) => (
  // allow scroll snapping & gap
  //  to happen on "first" ans "last" card
  <div {...props}>
    <div className="w-px"></div>
  </div>
);

type LatestNewsData = {
  allSanityBlog: {
    nodes: Array<ArticleNode>;
  };
};

const LatestNews = () => {
  const { locale } = useContext(i18nContext);
  const { navigateHandler } = useNavigateWithLocale();
  const data = (useStaticQuery(blogArticles) as LatestNewsData).allSanityBlog.nodes;
  const cards = useMemo(() => {
    const currentLocaleIndex = LANGUAGE_FORMATTED_LIST.findIndex((lang) => lang === locale) || 0;
    const rawArticles = handleCmsArticleRelatedData(data);
    return handleArticleRelatedDataToCardProps(rawArticles[currentLocaleIndex], SLUG.BLOG);
  }, [data, locale]);
  const scrollRef = useRef<HTMLDivElement>(null);
  const isOverflow = useOverflow(scrollRef);

  return (
    <Section background={{ color: COLOR.BACKGROUND_DARK }}>
      {/* remove section padding on screen < xl, for scrollable news cards */}
      <div className="-mx-4 flex flex-col items-center pt-4 pb-7 sm:py-14 md:-mx-6 xl:px-6 xl:pt-10 xl:pb-16">
        <Title className="items-center xl:items-start">
          <FormattedMessage id="HOME_NEWS_TITLE" />
        </Title>
        <div
          className="mt-7 flex w-full snap-x snap-mandatory flex-nowrap gap-x-6 overflow-x-scroll scrollbar-hide xl:mt-12 xl:max-w-none xl:overflow-x-auto"
          ref={scrollRef}
        >
          <LatestNewsScrollPadding className={isOverflow ? "" : "hidden"} />
          {cards.map((card, i) => (
            <LatestNewsCard {...card} isFirstCard={i === 0} key={card.title} />
          ))}
          <LatestNewsScrollPadding className={isOverflow ? "" : "hidden"} />
        </div>
        <div className="hidden xl:mt-12 xl:block">
          <GhostButton className="px-10 py-5" onClick={() => navigateHandler(SLUG.BLOG)}>
            <FormattedMessage id="HOME_NEWS_VIEW_MORE_POST" />
          </GhostButton>
        </div>
      </div>
    </Section>
  );
};

export default LatestNews;
