import React, { useState, useRef, useEffect } from "react";
import { useInView } from "react-intersection-observer";

enum VIDEO {
  DEFAULT = "https://www.youtube.com/embed/rrjUX7htwyM",
  AUTO_PLAY_AND_MUTE = "https://www.youtube.com/embed/rrjUX7htwyM?rel=0?version=3&autoplay=1&mute=1&loop=1&playlist=rrjUX7htwyM",
}

const Video = ({ className = "" }: { className?: string }) => {
  const [videoSrc, setVideoSrc] = useState<string>(VIDEO.AUTO_PLAY_AND_MUTE);
  const hasInView = useRef<boolean>(false);
  const { ref, inView } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if (hasInView.current || !inView) {
      return;
    }

    hasInView.current = true;
    setVideoSrc(VIDEO.AUTO_PLAY_AND_MUTE);
  }, [inView]);

  return (
    <div
      ref={ref}
      className={`
        h-48 w-72 rounded-md
        sm:h-52 sm:w-auto lg:h-72 xl:row-start-2 xl:row-end-3 xl:h-full xl:max-h-96 xl:w-full xl:max-w-2xl ${className}`}
    >
      <iframe
        src={videoSrc}
        title="Introducing BTQ"
        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        className="aspect-video h-full w-full"
      ></iframe>
    </div>
  );
};

export default Video;
