import React from "react";
import { FormattedMessage } from "react-intl";

import { Section } from "components/Section";
import { Title } from "components/Title";
import { SlideUp } from "components/Effects/SlideUp";
import { FeatureCard, FeatCardProps } from "./FeatureCard";

import quantumSecurity from "assets/home_feat_1.svg";
import verifiableScripting from "assets/home_feat_2.svg";
import ecoFriendlyMining from "assets/home_feat_3.svg";
import design from "constants/design";

const FEAT_LIST: Array<FeatCardProps> = [
  {
    image: quantumSecurity,
    title: <FormattedMessage id="HOME_FEATURES_TITLE_1" />,
    description: <FormattedMessage id="HOME_FEATURES_DESCRIPTION_1" />,
  },
  {
    image: verifiableScripting,
    title: <FormattedMessage id="HOME_FEATURES_TITLE_2" />,
    description: <FormattedMessage id="HOME_FEATURES_DESCRIPTION_2" />,
  },
  {
    image: ecoFriendlyMining,
    title: <FormattedMessage id="HOME_FEATURES_TITLE_3" />,
    description: <FormattedMessage id="HOME_FEATURES_DESCRIPTION_3" />,
  },
];

const Slogan = () => {
  const style = (chunks: React.ReactNode) => <span className="text-primary">{chunks}</span>;
  const values = { style };
  return <FormattedMessage id="HOME_FEATURES_SLOGAN" values={values} />;
};

const Features = () => (
  <Section
    background={{
      color: design.BACKGROUND_DARK,
    }}
  >
    <div className="pb-12 pt-5 sm:pt-20 sm:pb-10 xl:pt-28 xl:pb-16">
      <SlideUp className="flex w-full flex-col">
        <Title className="items-center text-center">
          <Slogan />
        </Title>
        <div
          className="
            mt-6 flex w-full flex-col items-center justify-center space-y-5
            xl:flex-row xl:items-start xl:space-x-3 xl:space-y-0"
        >
          {FEAT_LIST.map((feat) => (
            <FeatureCard {...feat} key={feat.image} />
          ))}
        </div>
      </SlideUp>
    </div>
  </Section>
);

export default Features;
