import * as React from "react";
import { Hero } from "views/home/hero";
// import { Partner } from "views/home/partner";
import Features from "views/home/features";
import Products from "views/home/products";
import AboutUs from "views/home/aboutUs";
import LatestNews from "views/home/latestNews";
import withI18n from "i18n/withI18n";
import { Layout } from "components/Layout";
import SEO from "components/seo";

const IndexPage = () => {
  return (
    <>
      <SEO title="BTQ" pathname="/" locale />
      <Layout>
        <Hero />
        {/* <Partner /> */}
        <Features />
        <Products />
        <AboutUs />
        <LatestNews />
      </Layout>
    </>
  );
};

export default withI18n(IndexPage);
