import { useContext, useMemo } from "react";
import { useStaticQuery, graphql } from "gatsby";

import { i18nContext } from "context/i18n";
import { getCmsLatestArticleData } from "./utils";
import type { articleInfo } from "./utils";

const newsTagResult = graphql`
  query GetLatestNewsTitle {
    allSanityBlog(sort: { fields: articlePublishedTime, order: DESC }, limit: 1) {
      nodes {
        _rawArticleTitle
        _rawArticleSlug
        articlePublishedTime
      }
    }
  }
`;

type latestArticleTagData = {
  allSanityBlog: {
    nodes: Array<articleInfo>;
  };
};

const useLatestNewsInfo = () => {
  const { locale } = useContext(i18nContext);
  const data = useStaticQuery<latestArticleTagData>(newsTagResult);

  return useMemo(
    () => getCmsLatestArticleData([data.allSanityBlog.nodes[0]], locale),
    [locale, data]
  );
};

export { useLatestNewsInfo };
