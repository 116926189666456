import React from "react";
import { FormattedMessage } from "react-intl";

import { useLatestNewsInfo } from "./hooks";

import { CustomLink } from "components/CustomLink";

import design from "constants/design";

export const LatestNews = () => {
  const { title, slug } = useLatestNewsInfo();

  return (
    <CustomLink href={slug}>
      <div className="mb-1 flex flex-col text-sm sm:mb-3 sm:text-base md:flex-row md:items-center">
        <span
          className={`mb-1 w-fit rounded-md px-1 py-px font-bold text-black sm:mb-0 sm:mr-2.5 ${design.GRADIENT_AQUA}`}
        >
          <FormattedMessage id="HOME_HERO_NEWS_TAG" />
        </span>
        <span className={`bg-clip-text font-medium text-transparent ${design.GRADIENT_AQUA}`}>
          {title}
        </span>
      </div>
    </CustomLink>
  );
};
