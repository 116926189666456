import React from "react";
import { FormattedMessage } from "react-intl";

import { Button } from "components/Button";
import { Section } from "components/Section";
import { Title } from "components/Title";
import KeelungBgImg from "./background";
import ProductStaticImage from "components/ProductStaticImage";
import { CustomLink } from "components/CustomLink";
import { SlideUp } from "components/Effects/SlideUp";

import {
  PRODUCTS,
  PRODUCTS_DESCRIPTION,
  PRODUCTS_NAME,
  PRODUCTS_SUBTITLE,
  PRODUCTS_WEBSITE,
} from "constants/products";
import { PRODUCT_STATIC_IMG_COMPONENT } from "./StaticMedia";
import COLOR from "constants/design";

type ProductList = {
  product: PRODUCTS;
  title: JSX.Element;
  subTitle: JSX.Element;
  description: JSX.Element;
  background?: Parameters<typeof Section>[0]["background"];
  link?: string;
};

const HOME_PRODUCTS_LIST: Array<ProductList> = [
  {
    product: PRODUCTS.PQSCALE,
    title: PRODUCTS_NAME.PQScale,
    subTitle: PRODUCTS_SUBTITLE.PQScale,
    description: PRODUCTS_DESCRIPTION.PQScale,
    background: { color: COLOR.BACKGROUND_LIGHT },
  },
  {
    product: PRODUCTS.KEELUNG,
    title: PRODUCTS_NAME.Keelung,
    subTitle: PRODUCTS_SUBTITLE.Keelung,
    description: PRODUCTS_DESCRIPTION.Keelung,
    link: PRODUCTS_WEBSITE.Keelung,
    background: { component: KeelungBgImg },
  },
];

const Product = ({ product, title, subTitle, description, background, link }: ProductList) => (
  <Section background={background}>
    <SlideUp className="justify-cetner grid w-full grid-cols-1 justify-items-center pt-7 pb-12 sm:py-14 xl:grid-cols-2 xl:justify-items-start xl:gap-x-8 xl:py-24">
      <div className="mb-5 grid grid-cols-1 xl:mb-0">
        <Title subTitle={subTitle}>{title}</Title>
        <p
          className="
        mt-3 max-w-2xl text-btq-sub-text-normal
        sm:text-xl xl:row-start-2 xl:row-end-4 xl:mb-5 xl:max-w-none xl:text-current"
        >
          {description}
        </p>
      </div>
      <ProductStaticImage
        product={product}
        alt=""
        staticImgComponentObj={PRODUCT_STATIC_IMG_COMPONENT}
      />
      {link && (
        <CustomLink href={link}>
          <Button className="sm:px-10 sm:py-5 xl:h-[3.75rem] xl:py-0">
            <FormattedMessage id="HOME_PRODUCT_LEARN_MORE" />
          </Button>
        </CustomLink>
      )}
    </SlideUp>
  </Section>
);

const Products = () => (
  <>
    {HOME_PRODUCTS_LIST.map((product) => (
      <Product {...product} key={product.product} />
    ))}
  </>
);

export default Products;
