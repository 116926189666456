import { DEFAULT_LANGUAGE } from "constants/i18n";
import type { LANGUAGE_CODE } from "website-metadata";
import { rawObjectWithString } from "interface/sanityNode";
import { SLUG } from "constants/slug";

export type articleInfo = {
  _rawArticleTitle: rawObjectWithString;
  _rawArticleSlug: { current: string };
  articlePublishedTime: string;
};

export const getCmsLatestArticleData = (articles: Array<articleInfo>, locale: LANGUAGE_CODE) => {
  const { _rawArticleTitle: title, _rawArticleSlug: slug } = articles.reduce(
    (result, { _rawArticleTitle, articlePublishedTime, _rawArticleSlug }) => {
      if (!result.articlePublishedTime || articlePublishedTime > result.articlePublishedTime) {
        return {
          _rawArticleTitle,
          articlePublishedTime,
          _rawArticleSlug,
        };
      }
      return result;
    },
    {} as articleInfo
  );

  return {
    slug: `${SLUG.BLOG}/${slug.current}`,
    title: title?.[locale] || title?.[DEFAULT_LANGUAGE],
  };
};
