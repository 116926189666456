import { useContext, useMemo } from "react";
import { navigate } from "gatsby";

import { i18nContext } from "context/i18n";

const useNavigateWithLocale = () => {
  const { locale } = useContext(i18nContext);
  const handler = useMemo(
    () => ({
      navigateHandler: (path: string) => navigate(`/${locale}${path}`),
    }),
    [locale]
  );

  return handler;
};

export default useNavigateWithLocale;
