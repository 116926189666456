import React from "react";
import BgImg from "assets/home_products_keelung_bg.inline.svg";

const Image = (props: React.ComponentProps<"div">) => (
  <div {...props} className={`${props.className} overflow-hidden`}>
    <BgImg className="absolute inset-y-0 right-0 h-full opacity-30" />
  </div>
);

export default Image;
