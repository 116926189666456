import { PRODUCTS } from "constants/products";
import React, { FC } from "react";

export interface StaticImgProps {
  alt: string;
}

type productStaticImgComponent = {
  [key in PRODUCTS]: FC<StaticImgProps> | null;
};

interface ProductStaticImageProps {
  product: PRODUCTS;
  alt: string;
  staticImgComponentObj: productStaticImgComponent;
}

const ProductStaticImage: FC<ProductStaticImageProps> = ({
  product,
  alt,
  staticImgComponentObj,
}) => {
  const StaticImg = staticImgComponentObj[product];
  return StaticImg && <StaticImg alt={alt} />;
};
export default ProductStaticImage;
