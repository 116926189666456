import React, { FC, HTMLProps } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { PRODUCTS } from "constants/products";
import { StaticImgProps } from "components/ProductStaticImage";
import aggSigAnimation from "assets/aggSigAnimation.gif";
interface MediaBoxProps extends HTMLProps<HTMLDivElement> {
  additionalLayoutStyle: string;
}

const MediaBox: FC<MediaBoxProps> = ({ additionalLayoutStyle, children }) => {
  return <div className={`mb-4 rounded-xl ${additionalLayoutStyle}`}>{children}</div>;
};

const imgClass = "h-40 w-72 sm:h-48 sm:w-full lg:h-full lg:max-w-lg xl:max-w-2xl";

const KeelungIntroStaticImg: FC<StaticImgProps> = ({ alt }) => {
  return (
    <MediaBox additionalLayoutStyle="xl:row-start-1 xl:row-end-3">
      <StaticImage
        src="../../../assets/home_products_keelung.png"
        alt={alt}
        objectFit="contain"
        className={imgClass}
      />
    </MediaBox>
  );
};

const PQScaleIntroStaticImg: FC<StaticImgProps> = ({ alt }) => {
  return (
    <MediaBox additionalLayoutStyle="xl:col-start-2 xl:row-start-1 xl:row-end-3 translate-x-2">
      <img src={aggSigAnimation} alt={alt} />
    </MediaBox>
  );
};

export const PRODUCT_STATIC_IMG_COMPONENT = {
  [PRODUCTS.KEELUNG]: KeelungIntroStaticImg,
  [PRODUCTS.PQSCALE]: PQScaleIntroStaticImg,
  [PRODUCTS.BTQNETWORK]: null,
  [PRODUCTS.QBYTE]: null,
  [PRODUCTS.KENTING]: null,
  [PRODUCTS.NIUM]: null,
};
