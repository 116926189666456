import React, { useState } from "react";
import { FormattedMessage } from "react-intl";

import useNavigateWithLocale from "hooks/useNavigateWithLocale";

import BgImg from "./background";
import { LatestNews } from "./LatestNews";
import { Button, GhostButton } from "components/Button";
import { Section } from "components/Section";
import { PopUp } from "components/Effects/PopUp";
import { ContactFormContent } from "components/NewsletterSubscribeForm/ContactForm";

import { SLUG } from "constants/slug";

const ContactUs = ({ className = "" }: { className?: string }) => (
  <div className={`flex flex-col gap-y-4 md:gap-y-5 ${className}`}>
    <h1 className="text-xl font-semibold not-italic md:text-4xl">Contact Us</h1>
    <ContactFormContent
      className="p-2"
      styleOverride={{ dropdown: { menu: { background: "#0C0C0C" } } }}
    />
    {/* clear the focus ring */}
  </div>
);

const Hero = () => {
  const { navigateHandler } = useNavigateWithLocale();
  const [showForm, setShowForm] = useState<boolean>(false);

  return (
    <>
      <PopUp show={showForm} onShowPopUp={setShowForm} className="z-50" slimBorder>
        <ContactUs />
      </PopUp>
      <Section background={{ component: BgImg }}>
        <div className="flex h-screen max-w-2xl flex-col items-start justify-center">
          <LatestNews />
          {/* font-size of primary heading ranges from 44px to 60px */}
          <h1 className="mb-2 whitespace-pre-line text-[max(min(3.75rem,5vw),2.75rem)] font-bold leading-[1.1] sm:mb-8">
            <FormattedMessage id="HOME_HERO_TITLE" />
          </h1>
          <p className="mb-4 leading-5 tracking-wide sm:mb-8 sm:text-xl">
            <FormattedMessage id="HOME_HERO_SUBTITLE" />
          </p>
          <div className="flex items-center">
            <Button
              className="mr-6 sm:px-10 sm:py-4"
              onClick={() => navigateHandler(SLUG.PRODUCTS)}
            >
              <FormattedMessage id="HOME_HERO_CTA" />
            </Button>
            <GhostButton
              className="sm:px-10 sm:py-4"
              onClick={(event) => {
                event.stopPropagation();
                setShowForm(true);
              }}
            >
              <FormattedMessage id="HOME_HERO_CONTACT_US" />
            </GhostButton>
          </div>
        </div>
      </Section>
    </>
  );
};

export { Hero };
